import { t } from '@lingui/macro';
import { NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utils';
import { formatCurrencyAmount } from 'noddi-util';

import { BOOKING_CATEGORY_ENUM } from '../../BookingFlow/Steps/SalesItems/utils';
import { variants } from '../../BookingFlow/Steps/Services/ServiceCategoryItem';

type ServiceItemProps = {
  serviceName: string;
  price?: number;
  type: BOOKING_CATEGORY_ENUM;
  discountedPrice?: number;
  hidePopular?: boolean;
};

const HighlightedSalesItem = ({ serviceName, price, type, discountedPrice, hidePopular }: ServiceItemProps) => {
  const isMobile = useIsMobile();
  const variant = variants(isMobile)[type];

  return (
    <div className='relative w-full'>
      {!hidePopular && (
        <div className='absolute -top-3 right-4 z-10 w-fit rounded-r-3xl rounded-tl-3xl bg-primary-purple px-3 py-1 text-primary-white shadow-md'>
          {t`Popular`}
        </div>
      )}
      <div
        className={cn(
          'relative flex w-full gap-2 overflow-hidden rounded-2xl bg-primary-white px-4 py-8 pt-6 shadow-sm'
        )}
      >
        <img className={cn('absolute right-0 top-1/2 sm:top-6')} src={variant.backgroundImage} alt='bg_image' />

        <div className='flex min-w-8 justify-center'>
          <div className='relative top-1'>
            <NoddiIcon name={variant.iconName} color={colors.primary.black} />
          </div>
        </div>

        <div className='flex w-full flex-col gap-1'>
          <p className='text-6'>{serviceName}</p>
          <div className='flex gap-1'>
            {price && <p className='line-through'>{price}</p>}
            {discountedPrice && <p className='text-signal-success'>{formatCurrencyAmount(discountedPrice, 0)}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HighlightedSalesItem };
