import { Trans } from '@lingui/macro';
import { BookingDataHome, BookingDataHomeBase, PaginatedResponse } from 'noddi-async/src/types';
import { NoddiButton } from 'noddi-ui';

import BookingCard from '../../components/Home/BookingCard';
import { DEFAULT_NUMBER_OF_BOOKINGS_SHOWN } from './HomeContent';

type BookingsSectionProps = {
  bookingsPaginated: PaginatedResponse<BookingDataHomeBase[]> | PaginatedResponse<BookingDataHome[]>;
  isUpcomingBooking?: boolean;
  showAllBookings: boolean;
  setShowAllBookings: React.Dispatch<React.SetStateAction<boolean>>;
};

const BookingsSection = ({
  bookingsPaginated,
  isUpcomingBooking,
  showAllBookings,
  setShowAllBookings
}: BookingsSectionProps) => {
  const bookings = bookingsPaginated.results;
  const totalBookings = bookingsPaginated.count;
  const isMoreBookings = totalBookings > DEFAULT_NUMBER_OF_BOOKINGS_SHOWN;

  if (!bookings.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-5 md:text-7'>
        {isUpcomingBooking ? <Trans>Upcoming bookings</Trans> : <Trans>Previous bookings</Trans>}
      </h2>
      <div className='flex flex-col gap-3'>
        {bookings.map((booking) => (
          <BookingCard key={booking.id} isUpcomingBooking={isUpcomingBooking} booking={booking} />
        ))}
      </div>
      {isMoreBookings && (
        <div className='mt-1 flex w-full justify-between px-5'>
          {!showAllBookings && (
            <p className='font-bold text-5'>{`+ ${totalBookings - DEFAULT_NUMBER_OF_BOOKINGS_SHOWN} more`}</p>
          )}
          <NoddiButton
            onClick={() => setShowAllBookings((prev) => !prev)}
            variant='secondary'
            size='small'
            className='ml-auto'
          >
            {showAllBookings ? <Trans>Show less</Trans> : <Trans>Show all</Trans>}
          </NoddiButton>
        </div>
      )}
    </div>
  );
};

export default BookingsSection;
