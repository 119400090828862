import { Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';

import { WheelSetProps } from 'noddi-async/src/types';
import { getCarDisplayName, NoddiButton, TireMeasurement, useIsMobile } from 'noddi-ui';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../appRoutes';
import getCommonTranslations from '../../commonTranslations';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import TireOfferExplanation from './TireOfferExplanation';
import TiresSvg from './TiresSvg';

function hasWheelMeasurementsData(wheels: WheelSetProps['wheels']) {
  return wheels.some((wheel) => wheel.measurements.length > 0);
}

const TireOffer = () => {
  const { slug } = useParams() as { slug: string };
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const query = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  return (
    <ContentWrapperWithQuery widerScreen hideBackButton query={query}>
      {({ data }) => (
        <div>
          <div className='mt-6 flex flex-col gap-6 md:flex-row-reverse md:items-center mdx:gap-25 xl:gap-40'>
            <div>
              <TiresSvg height={isMobile ? 131 : undefined} width={isMobile ? 192 : undefined} />
            </div>
            <div className='mb-2 flex flex-col gap-6 sm:mt-0'>
              <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>
                <Trans>Time for new tires?</Trans>
              </h1>
              <p className='text-primary-darkPurple'>
                <Trans>
                  Our measurements show that your winter tires may soon be, or are already, below the legal tread depth.
                </Trans>
              </p>
              <NoddiButton onClick={() => navigate(routes.tireOfferSelection.getPath({ slug }))} endIcon='ArrowRight'>
                <Trans>See offer for new tires</Trans>
              </NoddiButton>
              {hasWheelMeasurementsData(data.carWheelSet.wheels) && (
                <TireMeasurement
                  HeaderElement={
                    <p className='font-bold text-primary-darkPurple'>{getCarDisplayName(data.carWheelSet.car)}</p>
                  }
                  tireType={data.carWheelSet.type.label === 'Winter' ? 'winter' : 'summer'}
                  wheels={data.carWheelSet.wheels}
                  translations={getCommonTranslations().wheelMeasurement}
                />
              )}
            </div>
          </div>
          <div className='mt-10 flex flex-col gap-2 md:mt-20'>
            <h2 className='font-bold text-primary-darkPurple md:text-6'>
              <Trans>This is how it works</Trans>
            </h2>
            <TireOfferExplanation slug={slug} />
          </div>
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default TireOffer;
