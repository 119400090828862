import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, NoddiBasicCard, NoddiIcon, getLongAddressName } from 'noddi-ui';
import { useEffect } from 'react';

import HelpScoutChat from '../../../../components/BookingFlow/HelpScoutChat';
import { CustomerSMSLogin } from '../../../../components/CustomerSMSLogin';
import { useBookingContext } from '../../../../contexts/BookingContext';
import bookingTimeWindowToDisplayText from '../../../../helper/timeWindow';
import useActivateCoupon from '../../../../hooks/useActivateCoupon';
import BookingScreen from '../../BookingScreen';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import CommentBox from './CommentBox';
import ConfirmBooking from './ConfirmBooking';
import PriceSummary from './PriceSummary';

const Summary = () => {
  const { isLoggedIn } = useAuthContext();
  const { bookingInputData: inputData, updateBookingInputData, salesItems, bookingInputData } = useBookingContext();

  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();
  const { data: membershipData } = useMembershipPrograms();

  const handleCommentChange = (newComment: string) => {
    updateBookingInputData({ comment: newComment });
  };

  useEffect(() => {
    if (isLoggedIn && fetchedCoupons?.length !== 0) {
      salesItems.forEach((salesItem) => {
        activateCouponForSalesItem({ salesItemId: salesItem.id, licensePlateNumber: salesItem.licensePlateNumber });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, fetchedCoupons]);

  const timeWindowDisplay =
    (bookingInputData.time && bookingTimeWindowToDisplayText(bookingInputData.time)) ||
    t`Problems with the last time slot`;

  const bookingDataAddress = bookingInputData?.address;

  return (
    <BookingScreen
      title={t`Does this look correct?`}
      CustomBottomBar={
        <FixedBottomBarContainer>
          <HelpScoutChat />
          <ConfirmBooking membershipPrograms={membershipData} />
        </FixedBottomBarContainer>
      }
    >
      <div className='flex flex-col gap-3'>
        {isLoggedIn ? (
          <CommentBox comment={inputData.comment} handleChange={handleCommentChange} />
        ) : (
          <CustomerSMSLogin />
        )}

        <NoddiBasicCard className='flex flex-col gap-3'>
          {bookingDataAddress && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' />
              <p>{getLongAddressName(bookingDataAddress)}</p>
            </div>
          )}
          <div className='flex gap-2'>
            <NoddiIcon name='Calendar' />
            <div className='flex flex-col gap-1'>
              <p>{timeWindowDisplay}</p>
            </div>
          </div>
          <div className='flex gap-2'>
            <NoddiIcon name='Info' />
            <div className='flex flex-col gap-1'>
              <p>
                <Trans>
                  We'll text you the day before with estimated arrival time and when you're next on the list
                </Trans>
              </p>
            </div>
          </div>
        </NoddiBasicCard>

        <NoddiBasicCard>
          <PriceSummary membershipData={membershipData} />
        </NoddiBasicCard>
      </div>
    </BookingScreen>
  );
};

export default Summary;
