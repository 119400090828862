import { Trans } from '@lingui/macro';
import { AvailableBookingTimeWindow, MembershipDiscountProps, MembershipProgramProps } from 'noddi-async/src/types';
import { IconBasedOnSalesItem, NoddiIcon, colors } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useBookingSummaryProps from '../../../../hooks/useBookingSummaryProps';
import { ActiveCoupon } from '../../../../stores/CouponStore';
import { SelectedCar, SelectedSalesItem } from '../../interfaces';
import DiscountSection from './DiscountSection';
import { getNewPriceForSalesItem } from './utils';

type PriceSummaryProps = {
  membershipData?: MembershipProgramProps[] | undefined;
};

const PriceSummary = ({ membershipData }: PriceSummaryProps) => {
  const { totalPrice, coupons, discounts } = useBookingSummaryProps({ membershipData });

  const { bookingInputData, salesItems } = useBookingContext();

  const activeDiscountsForSalesItems = salesItems
    .map((salesItem) => {
      const discount = discounts.find((discount) => discount.salesItemIds.includes(salesItem.id));
      return {
        ...discount,
        price: salesItem.price
      };
    })
    .filter((item): item is MembershipDiscountProps & { price: number } => item?.id !== undefined);

  const activeCouponsForSalesItems = salesItems
    .map((salesItem) => {
      const coupon = coupons.find((coupon) => coupon.usedOnSalesItemId === salesItem.id);
      return {
        ...coupon,
        price: salesItem.price
      };
    })
    .filter((item): item is ActiveCoupon & { price: number } => item?.id !== undefined);

  const showDiscounts = activeCouponsForSalesItems.length > 0 || activeDiscountsForSalesItems.length > 0;

  return (
    <div className='flex flex-col gap-6'>
      {bookingInputData.selectedCars.map((car) => (
        <CarSummary
          key={car.licensePlateNumber}
          car={car}
          salesItems={salesItems.filter((x) => x.licensePlateNumber === car.licensePlateNumber)}
          discounts={discounts}
          coupons={coupons}
        />
      ))}
      {showDiscounts && (
        <DiscountSection
          activeCouponsForSalesItems={activeCouponsForSalesItems}
          activeDiscountsForSalesItems={activeDiscountsForSalesItems}
        />
      )}
      <TotalPrice totalPrice={totalPrice} timeWindow={bookingInputData.time} />
    </div>
  );
};

const CarSummary = ({
  car,
  salesItems,
  discounts,
  coupons
}: {
  car: SelectedCar;
  salesItems: SelectedSalesItem[];
  discounts: MembershipDiscountProps[];
  coupons: ActiveCoupon[];
}) => {
  const { mainSalesItems, addons } = salesItems.reduce(
    (acc, salesItem) => {
      if (salesItem?.isAddon) {
        acc.addons.push(salesItem);
      } else {
        acc.mainSalesItems.push(salesItem);
      }
      return acc;
    },
    { mainSalesItems: [] as SelectedSalesItem[], addons: [] as SelectedSalesItem[] }
  );

  return (
    <div className='flex flex-col gap-2'>
      <h1 className='text-5'>
        {car.carName} - {car.licensePlateNumber}
      </h1>

      <div className='flex flex-col gap-1'>
        {mainSalesItems.map((salesItem) => {
          return (
            <ServiceLine
              name={salesItem.name}
              categorySlug={salesItem?.bookingCategory?.slug}
              price={salesItem.price}
              id={salesItem.id}
              key={salesItem.id}
              isAddon={false}
              discounts={discounts}
              coupons={coupons}
            />
          );
        })}

        {addons.map((salesItem) => {
          return (
            <ServiceLine
              name={salesItem.name}
              categorySlug={salesItem?.bookingCategory?.slug}
              price={salesItem.price}
              id={salesItem.id}
              key={salesItem.id}
              isAddon
              discounts={discounts}
              coupons={coupons}
            />
          );
        })}
      </div>
    </div>
  );
};

type ServiceLineProps = {
  name: string;
  categorySlug?: string;
  price: number;
  id: number;
  isAddon: boolean;
  discounts: MembershipDiscountProps[];
  coupons: ActiveCoupon[];
};

const ServiceLine = ({ name, categorySlug, price, id, isAddon, discounts, coupons }: ServiceLineProps) => {
  const discountForSalesItem = discounts.find((discount) => discount.salesItemIds.includes(id));
  const couponForSalesItem = coupons.find((coupon) => coupon.validForSalesItemIds.includes(id));

  const newPrice =
    discountForSalesItem || couponForSalesItem
      ? getNewPriceForSalesItem({ price, discount: discountForSalesItem, coupon: couponForSalesItem })
      : 0;

  const hasTwoPrices = newPrice > 0;

  const Icon = () => {
    if (isAddon) {
      return (
        <div>
          <NoddiIcon name='Plus' size='medium' color={colors.primary.black} />
        </div>
      );
    }
    return (
      <div>
        <IconBasedOnSalesItem categorySlug={categorySlug} />
      </div>
    );
  };

  const Price = () => {
    if (hasTwoPrices) {
      return <span className='text-signal-success'>{formatCurrencyAmount(newPrice, 0)}</span>;
    }
    return <span>{formatCurrencyAmount(price, 0)}</span>;
  };

  return (
    <div className='flex flex-col'>
      <div className='flex w-full justify-between gap-4'>
        <div className='flex gap-2'>
          <Icon />
          <span>{name}</span>
        </div>
        <div className='text-end'>
          {hasTwoPrices && (
            <div className='flex w-full'>
              <span className='line-through'>{formatCurrencyAmount(price, 0)}</span>
            </div>
          )}
          <Price />
        </div>
      </div>
    </div>
  );
};

const TotalPrice = ({
  totalPrice,
  timeWindow
}: {
  totalPrice: number;
  timeWindow: AvailableBookingTimeWindow | null;
}) => {
  return (
    <div className='flex flex-col gap-1'>
      {!!timeWindow?.price && (
        <div className='flex justify-between'>
          <p>
            <Trans>Delivery</Trans>
          </p>
          <span>{formatCurrencyAmount(timeWindow?.price || 0, 0)}</span>
        </div>
      )}
      <div className='flex justify-between font-bold'>
        <p>
          <Trans>Total sum including VAT</Trans>
        </p>
        <span>{formatCurrencyAmount(totalPrice, 0)}</span>
      </div>
    </div>
  );
};

export default PriceSummary;
