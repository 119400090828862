import { Trans, t } from '@lingui/macro';
import { WheelStorageOptionType, wheelStorageOption } from 'noddi-async/src/types';
import { AvailableAddressPicker, NoddiBasicCard, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utils';
import { formatCurrencyAmount } from 'noddi-util';

import getCommonTranslations from '../../../../commonTranslations';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { useWheelStore } from '../../../../stores/WheelStore';

type WheelStorageOptionProps = {
  name: string;
  price: number;
  description?: string;
  handleClick: () => void;
  selectedStorageType: WheelStorageOptionType | undefined;
  currentStorageType: WheelStorageOptionType | undefined;
  licensePlateNumber: string;
};

const WheelStorageOption = ({
  name,
  description,
  price,
  handleClick,
  selectedStorageType,
  currentStorageType,
  licensePlateNumber
}: WheelStorageOptionProps) => {
  const isSelected = selectedStorageType === currentStorageType;
  const { addSelectedWheelStorageOptions } = useWheelStore();
  const { bookingInputData } = useBookingContext();
  const address = bookingInputData?.address;
  const isFree = price === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price);
  return (
    <NoddiBasicCard
      className={cn('relative flex w-full flex-col gap-2', isSelected && 'bg-primary-purple text-primary-white')}
    >
      <p className='text-5'>{name}</p>
      <p>{description}</p>
      {currentStorageType === wheelStorageOption.customer && (
        <div className='relative -left-1.5 mt-2 flex items-center'>
          <NoddiIcon name='LocationPin' color={isSelected ? colors.primary.white : colors.primary.black} />
          <p>
            {address?.name ?? address?.fullName} {address?.streetNumber}
          </p>
        </div>
      )}
      {isSelected && selectedStorageType && selectedStorageType !== wheelStorageOption.customer && (
        <div className='mt-2 max-w-100'>
          <AvailableAddressPicker
            translations={{
              couldNotFindAddress: getCommonTranslations().couldNotFindAddress,
              addressNotFound: getCommonTranslations().addressNotFound,
              tryAgainWithStreetNumber: getCommonTranslations().tryAgainWithStreetNumber,
              chooseFromAnotherAddress: getCommonTranslations().chooseFromAnotherAddress,
              chooseFromYourAddresses: getCommonTranslations().chooseFromYourAddresses
            }}
            pickerTranslations={{
              noServiceOfferedNotice: getCommonTranslations().noServiceOfferedNotice,
              noStreetNumberNotice: getCommonTranslations().noServiceOfferedNotice,
              somethingWentWrongWhileFetchingAddress: getCommonTranslations().somethingWentWrongWhileFetchingAddress
            }}
            clearAddress={() => {
              addSelectedWheelStorageOptions({
                licensePlateNumber,
                pickUpAddress: undefined
              });
            }}
            onSelectAddress={(address) => {
              if (address) {
                addSelectedWheelStorageOptions({
                  licensePlateNumber,
                  pickUpAddress: address
                });
              }
            }}
            addresses={[]}
          />
        </div>
      )}
      <div className='flex items-center justify-between'>
        <p className={cn('font-semibold', isFree && !isSelected && 'text-signal-success')}>{priceFormatted}</p>
        <NoddiButton variant='secondary' startIcon={isSelected ? 'Minus' : 'Plus'} size='small' onClick={handleClick}>
          {isSelected ? <Trans>Remove</Trans> : <Trans>Choose</Trans>}
        </NoddiButton>
      </div>
    </NoddiBasicCard>
  );
};

export { WheelStorageOption };
