import FormControl from '@mui/material/FormControl';
import { NoddiSelectableItem } from 'noddi-ui/src/molecules/NoddiSelectableItem';

import { RegisteredCar } from 'noddi-async/src/types';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';

type CarDropDownProps = {
  registeredCars: RegisteredCar[];
  translations: {
    myCars: string;
    add: string;
  };
};

export default function SavedCarList({ registeredCars, translations }: CarDropDownProps) {
  const mappedRegisteredCars =
    registeredCars?.map((car) => ({
      licensePlateNumber: car.licensePlateNumber,
      carName: `${car.make} ${car.model}`,
      carSizeId: car.carSizeId,
      vinNumber: car.vinNumber,
      licensePlateNumberCountryCode: car.countryCode
    })) ?? [];

  const { bookingInputData, updateBookingInputData } = useBookingContext();

  const handleChange = (selectedLicensePlate: string | undefined) => {
    const newlySelectedCarId = selectedLicensePlate;
    if (!newlySelectedCarId) {
      return;
    }

    const newSelectedCar = mappedRegisteredCars.find((car) => car.licensePlateNumber === newlySelectedCarId);

    if (!newSelectedCar) {
      return;
    }

    // don't add if already added
    const carExists = bookingInputData.selectedCars.some(
      (car) => car.licensePlateNumber === newSelectedCar.licensePlateNumber
    );

    if (carExists) {
      return;
    }

    updateBookingInputData({
      selectedCars: [
        ...bookingInputData.selectedCars,
        {
          licensePlateNumber: newSelectedCar.licensePlateNumber,
          carName: newSelectedCar.carName,
          carSizeId: newSelectedCar.carSizeId,
          vinNumber: newSelectedCar.vinNumber,
          licensePlateNumberCountryCode: newSelectedCar.licensePlateNumberCountryCode
        }
      ]
    });
    GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });
  };

  return (
    <FormControl variant='filled' size='medium' fullWidth>
      <p>{translations.myCars}</p>
      <div className='mb-4 mt-3 flex flex-col gap-3'>
        {mappedRegisteredCars?.map((car) => {
          // Check if car is already selected
          const hideButton = bookingInputData.selectedCars.some(
            (selectedCar) => selectedCar.licensePlateNumber === car.licensePlateNumber
          );

          return (
            <NoddiSelectableItem
              title={car.carName}
              description={car.licensePlateNumber}
              iconName='Car'
              hideButton={hideButton}
              isSelected={false}
              action={() => {
                handleChange(car.licensePlateNumber);
              }}
              actionText={translations.add}
              key={car.licensePlateNumber}
            />
          );
        })}
      </div>
    </FormControl>
  );
}
