import { t, Trans } from '@lingui/macro';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { NoddiLogo, useGetScreenDimensions, useNavigateOnLoginSuccess } from 'noddi-ui';
import styled from 'styled-components';

import routes from '../../appRoutes';
import { ContactUs } from '../../components/ContactUs';
import { CustomerSMSLogin } from '../../components/CustomerSMSLogin';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const { screenSize } = useGetScreenDimensions();

  const containerHeight = screenSize.height * 0.75;

  const { navigateOnLoginSuccess } = useNavigateOnLoginSuccess();

  const onLoginSuccess = () => navigateOnLoginSuccess({ loginSuccessPath: routes.homepage.getPath() });

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card>
          <Header>
            <h3 className='font-bold text-8'>
              <Trans>Log in</Trans>
            </h3>
            <NoddiLogo />
          </Header>

          <CardContent>
            <div className='flex flex-col gap-4'>
              <CustomerSMSLogin onLoginSuccess={onLoginSuccess} />
              <ContactUs customText={t`if you're having trouble logging in`} />
            </div>
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
