import { Trans, t } from '@lingui/macro';
import { tracking } from 'noddi-provider';
import { NoddiBasicCard, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

interface BookingConfirmationProps {
  bookingId?: string | null;
}

const BookingConfirmation = ({ bookingId }: BookingConfirmationProps) => {
  const navigate = useNavigate();

  return (
    <ContentWrapper hideBackButton title={t`Booking confirmed`}>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard className='flex flex-col gap-4 text-primary-darkPurple'>
            <p>
              <Trans>A confirmation message will be sent to you via SMS shortly.</Trans>
            </p>
            <div className='flex flex-col gap-1'>
              <p className='font-bold'>
                <Trans>Payment information</Trans> 💰
              </p>
              <p>
                <Trans>Once the job is complete, you'll receive a payment link via SMS.</Trans>
              </p>
            </div>
            {bookingId && (
              <div className='flex justify-end'>
                <NoddiButton
                  endIcon='ArrowRight'
                  onClick={() => navigate(routes.myBookingDetails.getPath({ id: bookingId }))}
                >
                  <Trans>See booking</Trans>
                </NoddiButton>
              </div>
            )}
          </NoddiBasicCard>
        </div>
        <NoddiBasicCard className='flex flex-col gap-4 bg-secondary-lightPurple text-secondary-black'>
          <p className='font-bold text-5'>
            <Trans>Enjoying Noddi so far?</Trans>
          </p>

          <p>
            <Trans>Refer a friend and you both get up to 300 kr in discounts on future bookings</Trans>💜
          </p>
          <div className='flex justify-end'>
            <NoddiButton
              variant='link'
              endIcon='ArrowRight'
              onClick={() => {
                tracking.track('referralPressedAfterBooking');
                navigate(routes.myCoupons.getPath());
              }}
              size='small'
            >
              <Trans>See details</Trans>
            </NoddiButton>
          </div>
        </NoddiBasicCard>
      </div>
    </ContentWrapper>
  );
};

export default BookingConfirmation;
