import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiLogo, colors } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import NavWrapper from './NavWrapper';

interface HomeHeaderNavProps {
  onNavOpen: () => void;
}

export const HomeHeaderNav = ({ onNavOpen }: HomeHeaderNavProps) => {
  const navigate = useNavigate();

  return (
    <NavWrapper>
      <div className='flex w-full items-center gap-6'>
        <button className='p-2' onClick={() => navigate(routes.homepage.getPath())}>
          <NoddiLogo color={colors.primary.orange} width={67} height={19} />
        </button>

        <div className='ml-auto flex gap-3'>
          <NoddiButton
            variant='secondary'
            className='rounded-full border-none'
            endIcon='HamburgerMenu'
            onClick={onNavOpen}
          >
            <Trans>Menu</Trans>
          </NoddiButton>
        </div>
      </div>
    </NavWrapper>
  );
};
