import { t } from '@lingui/macro';
import { Drawer } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiLanguagePicker, useIsMobile } from 'noddi-ui';
import { DrawerContent, PaperProps } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/SideNav';
import { useMobileNav } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/useMobileNav';
import { useFilteredNavItems } from 'noddi-ui/src/components/Elements/Layouts/useFilteredNavItems';
import { cn } from 'noddi-ui/src/helpers/utils';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { captureMessage } from '@sentry/react';
import { getSupportedLocales } from '../App';
import routes from '../appRoutes';
import HelpScoutChat from '../components/BookingFlow/HelpScoutChat';
import { HomeHeaderNav } from '../components/Layouts/HomeHeaderNav';
import ImpersonateUserButton from '../components/UserData/ImpersonateUserButton';
import useCleanupBooking from '../hooks/useCleanupBooking';
import useNavRoutes from '../hooks/useNavItems';
import { useTireHotelContext } from './TireHotel/BookingFlow/context';
import { TireStorageBookingSteps } from './TireHotel/BookingFlow/tireStorageBookingSteps';

export function HomeLayout() {
  const { tokenLoginError, isLoggedIn, isTokenLoadingOnMount } = useAuthContext();
  const { getNavItems } = useNavRoutes();
  const { pathname } = useLocation();

  const isTireHotelCancellationFlow = pathname.includes(routes.tireStorageCancelBooking.getPath());

  const mobileNav = useMobileNav();
  const isMobile = useIsMobile();

  const filteredNavItems = useFilteredNavItems(getNavItems());

  const { currentStep: currentStepOfTireHotel, resetTireHotelData } = useTireHotelContext();
  const { cleanupBookingData } = useCleanupBooking();

  //Closes the MobileNav after user clicks one of the menu items (route change)
  useEffect(() => {
    mobileNav.handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isTokenLoadingOnMount) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getPath()} />;
  }

  if (tokenLoginError && !isLoggedIn) {
    // This should never happen? Remove check if this never occurs in logs
    captureMessage('TokenLoginError and !isLoggedIn private/homeLayout', {
      level: 'warning'
    });
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getPath()} />;
    }
  }

  // Need the timer picker gradient on tier hotel edit time window page
  const isBookingEditPath = pathname.startsWith('/home/bookings') && pathname.endsWith('edit');
  const applyGradient =
    ((isTireHotelCancellationFlow && currentStepOfTireHotel === TireStorageBookingSteps.timePicker) ||
      isBookingEditPath) &&
    isMobile;

  const handleClearDataWhenImpersonating = () => {
    cleanupBookingData();
    resetTireHotelData();
  };

  return (
    <div className={cn('min-h-screen bg-peach-to-purple', applyGradient && 'bg-pinto-to-white-gradient')}>
      <HomeHeaderNav onNavOpen={mobileNav.handleOpen} />

      <Drawer
        anchor='left'
        onClose={mobileNav.handleClose}
        open={mobileNav.open}
        PaperProps={PaperProps()}
        variant='temporary'
      >
        <DrawerContent
          translations={{ profile: t`My account`, logout: t`Log out` }}
          navItems={filteredNavItems}
          LanguagePicker={<NoddiLanguagePicker supportedLanguages={getSupportedLocales()} />}
          CustomNavElement={
            <ImpersonateUserButton handleClearDataWhenImpersonating={handleClearDataWhenImpersonating} />
          }
          customPathToProfile={routes.profile.getPath()}
          handleClearDataWhenImpersonating={handleClearDataWhenImpersonating}
        />
      </Drawer>

      <Outlet />
      <div className='fixed bottom-10 right-10 z-50'>
        <HelpScoutChat position='right' size='small' />
      </div>
    </div>
  );
}
