import { Trans, t } from '@lingui/macro';
import { Referral } from 'noddi-async/src/types';
import { NoddiFeedbackBox, NoddiIcon } from 'noddi-ui';

const ReferredUsers = ({ referrals }: { referrals: Referral[] }) => {
  return (
    <div className='flex flex-col gap-2'>
      <p>
        <Trans>Rewards you have received for referring friends</Trans>:
      </p>
      {referrals.map((referralDetails) => {
        return (
          <div key={referralDetails.referredUserName} className='flex flex-col gap-2 rounded-2xl border p-4'>
            <div className='flex items-center gap-2'>
              <NoddiIcon name='User' size='medium' />
              <h6 className='font-bold'>{referralDetails.referredUserName}</h6>
            </div>
            {referralDetails.receivedCoupons.map((referralCoupon, index) => {
              const coupon = referralCoupon.coupon;
              const amount = coupon.amount;
              const amountPercentage = coupon.amountPercentage;
              return (
                (amount || amountPercentage) && (
                  <div className='flex items-center gap-2'>
                    <NoddiIcon name='Cup' size='medium' />
                    <div className='flex items-center gap-2' key={index}>
                      <p>{amount ? t`Amount` : t`Discount`}</p>
                      <p>{amount ? `${amount} kr` : `${amountPercentage} %`}</p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        );
      })}
      {referrals.length === 0 && <NoddiFeedbackBox variant='info' heading={t`You have not referred any users yet`} />}
    </div>
  );
};

export default ReferredUsers;
