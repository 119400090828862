import { UserDataProps } from 'noddi-async/src/types';
import { tracking, useLanguageContext } from 'noddi-provider';
import { SMSLogin, UserRegistrationForm } from 'noddi-ui';
import getCommonTranslations from '../commonTranslations';
import { getErrorCodes } from '../utils/translation';

interface CustomerSMSLoginProps {
  onLoginSuccess?: (userData: UserDataProps) => void;
  hidePhoneNumberText?: boolean;
}
export const CustomerSMSLogin = ({ onLoginSuccess, hidePhoneNumberText }: CustomerSMSLoginProps) => {
  const { currentLanguage } = useLanguageContext();

  return (
    <SMSLogin
      errorCodes={getErrorCodes()}
      translations={getCommonTranslations().smsLoginTranslations}
      language={currentLanguage}
      hidePhoneNumberText={hidePhoneNumberText}
      trackUserIdentity={(user) => {
        tracking.trackUserLogin(user);
      }}
      onLoginSuccess={onLoginSuccess}
      userRegistrationForm={(phoneNumber, onUserRegistrationLoginSuccess) => (
        <UserRegistrationForm
          onLoginSuccess={onUserRegistrationLoginSuccess}
          errorCodes={getErrorCodes()}
          translations={getCommonTranslations().registrationTranslations}
          phoneNumber={phoneNumber}
          skipPasswordCreation
          trackUserIdentity={(user) => {
            tracking.trackUserIdentity(user);
          }}
        />
      )}
    />
  );
};
