import { useSearchParams } from 'react-router-dom';

import BookingConfirmation from './Booking';

const ConfirmationPage = () => {
  const [searchParams] = useSearchParams();

  const bookingId = searchParams.get('bookingId');

  return <BookingConfirmation bookingId={bookingId} />;
};

export default ConfirmationPage;
