import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiFeedbackBox, NoddiIcon, NoddiTextInput } from 'noddi-ui';
import { useState } from 'react';

interface EditCommentProps {
  commentsUser: string;
  bookingId: string;
}

const EditComment = ({ commentsUser, bookingId }: EditCommentProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userComment, setUserComment] = useState(commentsUser);
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const {
    mutateAsync: updateBooking,
    isPending: isUpdateBookingLoading,
    isError
  } = noddiAsync.usePatch({
    type: URLKeys.patchBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { userGroupId: userGroupId as number, bookingId }
        });
        setIsEditing(false);
      }
    }
  });

  const hasAnyComment = commentsUser.trim().length > 0;
  return (
    <div className='flex flex-col gap-2'>
      {hasAnyComment && (
        <div className='flex gap-2'>
          <div>
            <NoddiIcon name='ChatRounded' size='medium' />
          </div>
          <p>{commentsUser}</p>
        </div>
      )}

      {isEditing ? (
        <div className='flex flex-col gap-2'>
          <NoddiTextInput
            value={userComment}
            onChange={(newValue) => setUserComment(newValue.target.value)}
            error={isError}
          />
          {isError && (
            <NoddiFeedbackBox heading={t`We had trouble updating your booking. Please try again`} variant='error' />
          )}
          <div className='flex justify-end'>
            <NoddiButton
              variant='secondary'
              loading={isUpdateBookingLoading}
              onClick={async () =>
                await updateBooking({
                  id: parseInt(bookingId),
                  commentsUser: userComment
                })
              }
            >
              <Trans>Save</Trans>
            </NoddiButton>
          </div>
        </div>
      ) : (
        <NoddiButton
          startIcon='Edit'
          variant='link'
          onClick={() => setIsEditing((prev) => !prev)}
          className='-ml-4 -mt-2 gap-2'
        >
          {hasAnyComment ? t`Edit comment` : t`Add a comment`}
        </NoddiButton>
      )}
    </div>
  );
};

export default EditComment;
