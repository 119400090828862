import { NavigateFunction } from 'react-router-dom';

import routes from '../appRoutes';

const routeToConfirmation = ({ navigate, id }: { id: number | null; navigate: NavigateFunction }) => {
  const queryParams = new URLSearchParams({
    bookingId: id?.toString() ?? ''
  });

  navigate(routes.confirmation.getPath({ params: queryParams.toString() }));
};

export { routeToConfirmation };
