import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { tracking, useAuthContext } from 'noddi-provider';
import {
  AddressPicker,
  NoddiBasicCard,
  NoddiButton,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader,
  colors,
  getLongAddressName
} from 'noddi-ui';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import getCommonTranslations from '../../commonTranslations';
import { useBookingContext } from '../../contexts/BookingContext';
import useOnNextButtonClick from '../../pages/BookingFlow/Steps/Address/useOnNextButtonClick';
import { GoogleTrackingService } from '../../services/GoogleTrackingService';
import { GtmEvents } from '../../types/gtmTracking';
import { SelectedAddressDetails } from './SelectedAddressDetails';

interface AddressPickerComponentProps {
  hideServiceInfoBox?: boolean;
}

const AddressPickerComponent = ({ hideServiceInfoBox }: AddressPickerComponentProps) => {
  const { currentUserGroupId: userGroupId, isLoggedIn } = useAuthContext();
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  const { navigateToNextStep } = useOnNextButtonClick();
  const { address } = bookingInputData;

  const {
    isLoading: isServiceAreasLoading,
    isError: isServiceAreaError,
    refetch: refetchServiceAreas,
    data: serviceAreas
  } = noddiAsync.useGet({
    type: URLKeys.getServiceAreasFromCoordinate,
    input: { lat: address?.latitude, lng: address?.longitude },
    queryConfig: {
      enabled: Boolean(address),
      staleTime: Infinity
    }
  });

  const { isLoading: userGroupAddressesIsLoading, data: userGroupAddresses } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddresses,
    input: { userGroupId: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const [searchParams, _] = useSearchParams();
  const placeIdFromUrl = searchParams.get('address');
  const showProgressLoader = isServiceAreasLoading || userGroupAddressesIsLoading;

  const hasServiceCategories = Boolean(serviceAreas?.serviceCategories?.length ?? 0 > 0);

  useEffect(() => {
    if (address) {
      refetchServiceAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  // each time the address changes, we want to update the serviceAreas and serviceCategories
  // that the user can choose from later in the booking flow
  useEffect(() => {
    if (serviceAreas) {
      updateBookingInputData({
        serviceAreas: serviceAreas?.serviceAreas,
        serviceCategories: serviceAreas?.serviceCategories ?? []
      });
    }

    if (serviceAreas && !hasServiceCategories) {
      tracking.track('noServiceOffered', {
        address
      });

      GoogleTrackingService.trackEvent({
        eventType: GtmEvents.noServiceOffered
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAreas]);

  const addressDropdownOptions = useMemo(() => {
    if (!userGroupAddresses) {
      return [];
    }

    const mappedAddresses = userGroupAddresses.map((item) => item.address);

    return mappedAddresses;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupAddresses, address]);

  const pickedFullAddressName = address?.fullAddress?.replaceAll('undefined', '');

  return (
    <>
      {pickedFullAddressName ? (
        <NoddiBasicCard className='flex w-full items-center justify-between gap-2'>
          <div className='flex items-center gap-1'>
            <NoddiIcon name='LocationPin' size='large' />
            <p>{pickedFullAddressName}</p>
          </div>

          <NoddiIconButton
            iconName='Cross'
            iconSize='medium'
            variant='destructive'
            onClick={() => {
              updateBookingInputData({ address: null });
            }}
          />
        </NoddiBasicCard>
      ) : (
        <AddressPicker
          translations={{
            couldNotFindAddress: getCommonTranslations().couldNotFindAddress,
            addressNotFound: getCommonTranslations().addressNotFound,
            tryAgainWithStreetNumber: getCommonTranslations().tryAgainWithStreetNumber,
            chooseFromAnotherAddress: getCommonTranslations().chooseFromAnotherAddress,
            chooseFromYourAddresses: getCommonTranslations().chooseFromYourAddresses
          }}
          hideBottomOptions
          addresses={[]}
          onSelectAddress={(address) => {
            if (!address) {
              return;
            }
            const updatedAddress = { ...address };
            updateBookingInputData({ address: updatedAddress });
          }}
          selectedAddress={address}
          placeIdFromUrl={placeIdFromUrl}
          initWithCustomSearch={!isLoggedIn}
        />
      )}

      {addressDropdownOptions.length > 0 && !pickedFullAddressName && (
        <div className='mt-8 flex flex-col gap-4'>
          <p>
            <Trans>Saved addresses</Trans>
          </p>
          {addressDropdownOptions.map((ad) => (
            <NoddiBasicCard key={ad.fullAddress} className='flex items-center justify-between gap-4'>
              <div className='flex gap-2'>
                <NoddiIcon name='Home' color={colors.primary.black} />
                <div className='flex flex-col'>
                  <p>{getLongAddressName(ad)}</p>
                </div>
              </div>
              <div className='flex items-center'>
                <NoddiButton
                  variant='secondary'
                  size='small'
                  onClick={() => {
                    const updatedAddress = { ...ad };
                    updateBookingInputData({ address: updatedAddress });
                  }}
                >
                  <Trans>Choose</Trans>
                </NoddiButton>
              </div>
            </NoddiBasicCard>
          ))}
        </div>
      )}

      {showProgressLoader ? (
        <NoddiLinearProgressLoader />
      ) : (
        <SelectedAddressDetails
          translations={{
            noServiceOfferedNotice: getCommonTranslations().noServiceOfferedNotice,
            noStreetNumberNotice: getCommonTranslations().noStreetNumberNotice,
            from: getCommonTranslations().from,
            hooray: getCommonTranslations().hooray,
            youCanBookTheseServicesToYourAddress: getCommonTranslations().youCanBookTheseServicesToYourAddress,
            weDoNotOfferTheseServicesAtThisAddress: getCommonTranslations().weDoNotOfferTheseServicesAtThisAddress
          }}
          hideServiceInfoBox={hideServiceInfoBox}
          isServiceAreaError={isServiceAreaError}
          serviceAreas={serviceAreas}
          hasServiceCategories={hasServiceCategories}
          address={address}
          navigateToNextStepWhenAvailableService={navigateToNextStep}
        />
      )}
    </>
  );
};

export default AddressPickerComponent;
