import { Trans } from '@lingui/macro';
import { CustomerSMSLogin } from './CustomerSMSLogin';

interface BenefitsLoginProps {
  activateMemberShipAfterLogin?: (groupId: number | null, phoneNumber?: string) => Promise<void>;
}
export const BenefitsLogin = ({ activateMemberShipAfterLogin }: BenefitsLoginProps) => {
  return (
    <div className='flex flex-col gap-2'>
      <p className='mt-5 font-bold text-5 text-primary-darkPurple'>
        <Trans>To activate your benefits, we first need to verify your phone number</Trans>
      </p>

      <p className='text-primary-darkPurple opacity-80'>
        <Trans>We will create a user for you, if you do not have one</Trans>
      </p>
      <div className='text-primary-darkPurple'>
        <CustomerSMSLogin
          hidePhoneNumberText
          onLoginSuccess={async (data) => {
            await activateMemberShipAfterLogin?.(data.user.userGroups[0]?.id ?? null, data.user.phoneNumber);
          }}
        />
      </div>
    </div>
  );
};
