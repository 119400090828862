import { useEffect, useRef } from 'react';

export function useEffectAfterMount(effect: React.EffectCallback, dependencies: React.DependencyList) {
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
      // Run the effect only if the component has already mounted
      effect();
    } else {
      isMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
