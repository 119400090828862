import { LicensePlateNumberData, RegisteredCar } from 'noddi-async/src/types';
import { RegNumberSearch } from 'noddi-ui';
import { CountryCode } from 'noddi-util';
import { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import getCommonTranslations from '../../../../commonTranslations';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { getErrorCodes } from '../../../../utils/translation';
import CarListItem from './CarListItem';
import { getLicensePlateCountryOptions, LicensePlateCountryOption } from './LicensePlateCountryOptions';
import { LicensePlateCountrySelector } from './LicensePlateCountrySelector';
import SavedCarList from './SavedCarList';

type AddMoreCarsViewProps = {
  registeredCars?: RegisteredCar[];
};

const AddMoreCarsView = ({ registeredCars }: AddMoreCarsViewProps) => {
  const { bookingInputData } = useBookingContext();

  const defaultCountryCodeBasedOnAddress = bookingInputData.address?.countryCode;
  const defaultCountryCodeOption = getLicensePlateCountryOptions().find(
    (option) => option.value === defaultCountryCodeBasedOnAddress
  );

  return <AddMoreCars registeredCars={registeredCars} defaultCountryCodeOption={defaultCountryCodeOption} />;
};

type AddMoreCarsProps = {
  registeredCars?: RegisteredCar[];
  defaultCountryCodeOption?: LicensePlateCountryOption;
};

const AddMoreCars = ({ registeredCars, defaultCountryCodeOption }: AddMoreCarsProps) => {
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  const [licensePlateCountry, setLicensePlateCountry] = useState(defaultCountryCodeOption);

  const selectedCars = bookingInputData.selectedCars;

  const onAddRegistrationNumber = (selectedCar: LicensePlateNumberData) => {
    const alreadyExists = selectedCars.some((car) => car.licensePlateNumber === selectedCar.licensePlateNumber);
    if (alreadyExists) {
      return;
    }

    updateBookingInputData({
      selectedCars: [
        ...selectedCars,
        {
          licensePlateNumber: selectedCar.licensePlateNumber,
          carName: `${selectedCar.make} ${selectedCar.model}`,
          carSizeId: selectedCar.carSizeId,
          vinNumber: selectedCar.vinNumber,
          licensePlateNumberCountryCode: licensePlateCountry?.value
        }
      ]
    });
    GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });
  };

  const alreadyPickedLicensePlateNumbers = selectedCars.map((car) => car.licensePlateNumber);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <RegNumberSearch
          onAddCar={onAddRegistrationNumber}
          errorCodes={getErrorCodes()}
          alreadyPickedLicensePlateNumbers={alreadyPickedLicensePlateNumbers}
          registeredCars={registeredCars}
          translations={{
            add: getCommonTranslations().add,
            regNumberLabel:
              licensePlateCountry?.value === CountryCode.NORWAY
                ? getCommonTranslations().regNumberLabelNO
                : getCommonTranslations().regNumberLabelSE,
            placeholder:
              licensePlateCountry?.value === CountryCode.NORWAY
                ? getCommonTranslations().regNumberPlaceholderNO
                : getCommonTranslations().regNumberPlaceholderSE
          }}
          countryCode={licensePlateCountry?.value}
        />
        <LicensePlateCountrySelector
          setLicensePlateCountry={setLicensePlateCountry}
          licensePlateCountry={licensePlateCountry}
        />
      </div>
      {!!selectedCars.length && (
        <div>
          <p>
            <Trans>Selected cars</Trans>
          </p>
          <div className='flex flex-col gap-3'>
            {selectedCars.map((car) => (
              <CarListItem key={car.licensePlateNumber} selectedCar={car} />
            ))}
          </div>
        </div>
      )}

      {!!registeredCars?.length && (
        <SavedCarList
          registeredCars={registeredCars}
          translations={{ myCars: t`Saved cars`, add: getCommonTranslations().add }}
        />
      )}
    </div>
  );
};

export default AddMoreCarsView;
