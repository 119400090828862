import { IconName, NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utils';

import { BOOKING_CATEGORY_ENUM } from '../SalesItems/utils';

type ServiceItemProps = {
  serviceName: string;
  priceFrom?: string;
  description?: string;
  type: BOOKING_CATEGORY_ENUM;
  isPopular?: boolean;
  popularLabel?: string;
  isExpanded: boolean;
  selectedItemsCount: number;
};

type Variant = {
  backgroundImage: string;
  iconName: IconName;
};

export const variants = (isMobile: boolean): Record<BOOKING_CATEGORY_ENUM, Variant> => ({
  [BOOKING_CATEGORY_ENUM.PACKAGES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/car_care.svg`,
    iconName: 'Heart'
  },
  [BOOKING_CATEGORY_ENUM.WASHING_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/washing_package.svg`,
    iconName: 'Drop'
  },
  [BOOKING_CATEGORY_ENUM.WHEEL_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/wheel_service.svg`,
    iconName: 'WheelChange'
  }
});

const ServiceCategoryItem = ({
  serviceName,
  description,
  priceFrom,
  type,
  isPopular,
  popularLabel,
  isExpanded,
  selectedItemsCount = 0
}: ServiceItemProps) => {
  const isMobile = useIsMobile();
  const variant = variants(isMobile)[type];

  return (
    <div className='relative w-full'>
      {isPopular && (
        <div className='absolute -top-6 right-4 z-10 w-fit rounded-r-3xl rounded-tl-3xl bg-primary-purple px-4 py-2 text-primary-white'>
          {popularLabel}
        </div>
      )}
      <div
        className={cn(
          'relative flex w-full gap-2 overflow-hidden rounded-2xl bg-primary-white bg-no-repeat px-4 pb-8 pt-6 transition-all duration-300 sm:pb-12',
          isExpanded && '!min-h-[unset]'
        )}
      >
        <img
          className={cn(
            'absolute right-0 top-1/2 transition-all duration-300 sm:top-6',
            isExpanded ? 'opacity-0' : 'opacity-100'
          )}
          src={variant.backgroundImage}
          alt='bg_image'
        />
        <div className='absolute right-4 top-6 sm:right-11 sm:top-1/2'>
          <NoddiIcon name={isExpanded ? 'AltArrowUp' : 'AltArrowDown'} color={colors.primary.black} />
        </div>

        <div className='flex min-w-8 justify-center'>
          {selectedItemsCount > 0 ? (
            <div className='relative top-1 flex size-8 items-center justify-center rounded-full bg-primary-purple text-primary-white'>
              {selectedItemsCount}
            </div>
          ) : (
            <div className='relative top-1'>
              <NoddiIcon name={variant.iconName} color={colors.primary.black} />
            </div>
          )}
        </div>

        <div className='flex w-full flex-col gap-1'>
          <p className='text-6'>{serviceName}</p>
          {priceFrom && <p>{priceFrom}</p>}
          {description && <p className='text-text-primary opacity-70 sm:w-2/3'>{description}</p>}
        </div>
      </div>
    </div>
  );
};

export { ServiceCategoryItem };
