import { captureMessage } from '@sentry/react';
import { NavigateFunction } from 'react-router-dom';

export const navigateAndLog = (activeStep: string, failedStep: string, navigate: NavigateFunction) => {
  const logMsg = `User is currently on step ${activeStep}. Navigating to step ${failedStep} due to unmet requirements.`;

  if (import.meta.env.MODE !== 'production') {
    console.error(logMsg);
  } else {
    captureMessage(logMsg, {
      level: 'warning'
    });
  }

  navigate(failedStep);
};
