import { Fragment, ReactElement, useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

import { tracking } from './tracking';

// Component so that we can create funnels with screen trackings in mixpanel
export function TrackScreen(): ReactElement {
  const location = useLocation();

  useEffect(() => {
    const { pathname, searchParams } = getTrackingPathFromLocation(location);
    tracking.track(pathname, searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <Fragment />;
}

function getTrackingPathFromLocation(location: Location) {
  const pathname = location.pathname;
  const search = location.search;
  const searchParams = location.search ? { search: search.replace('?', '') } : undefined;
  return { pathname, searchParams };
}
