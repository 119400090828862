import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { colors } from '../../../../tailwind-design-preset';
import { commonTexts } from '../../../commonTexts';
import { useIsMobile } from '../../../hooks';
import { BaseTableProps, NoddiCardProps, TableProps } from '../../../types';
import { NoddiCard } from '../Cards';
import { NoddiCircularLoader } from '../Loaders';
import { NoddiSearchBar, PaginatedWrapper } from '../Navigation';
import NoddiActiveFilterChips from './Filters/ActiveFilterChips';
import { FilterSpacer } from './Filters/FilterSpacer';
import { NoddiTableFilterList } from './Filters/NoddiTableFilterList';

function NoddiBaseTable<Item>({
  items = [],
  isLoading,
  headers,
  getUrlPath,
  searchProps,
  onRowClick,
  emptyStateText,
  dontCollapseOnMobile
}: BaseTableProps<Item>) {
  const isMobile = useIsMobile();

  // only use first 0-2 headers on mobile
  const dynamicHeaders = isMobile && !dontCollapseOnMobile ? headers.slice(0, 2) : headers;
  const dynamicItems =
    isMobile && !dontCollapseOnMobile
      ? items.map((item) => ({ ...item, renderItems: item.renderItems.slice(0, 2) }))
      : items;

  return (
    <Table sx={{ marginTop: searchProps ? '20px' : null }}>
      <TableHead
        sx={{
          '& .MuiTableRow-root': {
            backgroundColor: colors.systemColors.outlineStroke,
            color: colors.systemColors.grey
          },
          '& .MuiTableRow-root th:first-of-type': {
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px'
          },
          '& .MuiTableRow-root th:last-of-type': {
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px'
          }
        }}
      >
        <TableRow>
          {dynamicHeaders.length > 0 && dynamicHeaders.map((item, index) => <TableCell key={index}>{item}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {dynamicItems.length === 0 && emptyStateText && !isLoading ? (
          <TableRow>
            <TableCell style={{ textAlign: 'center', padding: '20px' }} colSpan={dynamicHeaders.length}>
              {emptyStateText}
            </TableCell>
          </TableRow>
        ) : (
          dynamicItems.map((item, index) => (
            <Fragment key={index}>
              {getUrlPath ? (
                <TableRow component={Link} className='cursor-pointer' to={getUrlPath(item.item)} hover key={index}>
                  {item.renderItems.map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow
                  style={onRowClick ? { cursor: 'pointer' } : undefined}
                  onClick={() => onRowClick?.(item.item)}
                  hover
                  key={index}
                >
                  {item.renderItems.map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                </TableRow>
              )}
            </Fragment>
          ))
        )}
      </TableBody>
    </Table>
  );
}

export function NoddiTable<Item>({
  count = 0,
  items = [],
  setPage,
  setPageSize,
  page = 0,
  pageSize = 10,
  isLoading,
  headers,
  bgColor = 'white',
  getUrlPath,
  onRowClick,
  searchProps,
  emptyStateText,
  filters,
  clearFilters,
  wrapWithCard = true,
  dontCollapseOnMobile,
  translations,
  ...cardProps
}: TableProps<Item> & Omit<NoddiCardProps, 'children'>) {
  const isMobile = useIsMobile();

  const texts = translations ?? commonTexts.table;
  // only use first 0-2 headers on mobile
  const dynamicHeaders = isMobile && !dontCollapseOnMobile ? headers.slice(0, 2) : headers;
  const dynamicItems =
    isMobile && !dontCollapseOnMobile
      ? items.map((item) => ({ ...item, renderItems: item.renderItems.slice(0, 2) }))
      : items;

  const debouncedSearchValue = debounce(searchProps?.onSearch || (() => {}), 200);

  const isPaginated = count && setPage && setPageSize && page && pageSize;

  const TableContent = (
    <div>
      <Stack direction='column'>
        {searchProps && (
          <NoddiSearchBar
            handleChange={debouncedSearchValue}
            placeholder={searchProps.placeholder}
            sx={{
              width: '100%',
              maxWidth: '600px',
              marginRight: '12px',
              marginBottom: filters ? '12px' : '0',
              boxSizing: 'border-box',
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                height: '100%'
              }
            }}
          />
        )}
        {filters && (
          <FilterSpacer>
            <Stack direction='row' gap={0.5} width='100%' flexWrap='wrap' justifyContent='flex-start'>
              <NoddiTableFilterList filters={filters} clearFilters={clearFilters} translations={texts} />
            </Stack>
          </FilterSpacer>
        )}
        {filters && <NoddiActiveFilterChips filters={filters ?? []} />}
      </Stack>
      <Box sx={{ position: 'relative' }} bgcolor={bgColor}>
        {isLoading ? (
          <Stack minHeight={200} alignItems='center' justifyContent='center'>
            <NoddiCircularLoader />
          </Stack>
        ) : isPaginated ? (
          <PaginatedWrapper
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
            translations={texts}
          >
            <NoddiBaseTable
              items={dynamicItems}
              isLoading={isLoading}
              headers={dynamicHeaders}
              getUrlPath={getUrlPath}
              onRowClick={onRowClick}
              searchProps={searchProps}
              emptyStateText={emptyStateText}
              dontCollapseOnMobile={dontCollapseOnMobile}
            />
          </PaginatedWrapper>
        ) : (
          <NoddiBaseTable
            items={dynamicItems}
            isLoading={isLoading}
            headers={dynamicHeaders}
            getUrlPath={getUrlPath}
            onRowClick={onRowClick}
            searchProps={searchProps}
            emptyStateText={emptyStateText}
            dontCollapseOnMobile={dontCollapseOnMobile}
          />
        )}
      </Box>
    </div>
  );

  if (!wrapWithCard) {
    return TableContent;
  }
  return (
    <NoddiCard disableSpacing {...cardProps}>
      {TableContent}
    </NoddiCard>
  );
}
