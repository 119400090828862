/**
 * Works much like the {@link AddressPicker.tsx}, but this also validates that we can delivery our services there
 **/
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressProps } from 'noddi-async/src/types';
import { useEffect, useState } from 'react';

import { ErrorMessage, NoddiLinearProgressLoader, TypographyWithInfoSign } from '../../Elements';
import { AddressPicker, AddressPickerProps } from './AddressPicker';
import { hasStreetNumber } from './addressUtils';

type ClearAddress = { clearAddress: () => void };

type Translations = {
  pickerTranslations: {
    noServiceOfferedNotice: string;
    noStreetNumberNotice: string;
    somethingWentWrongWhileFetchingAddress: string;
  };
};
const AvailableAddressPicker = ({
  clearAddress,
  onSelectAddress,
  translations,
  pickerTranslations,
  ...params
}: AddressPickerProps & ClearAddress & Translations) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressProps | null>(null);

  const {
    isLoading: isServiceAreasLoading,
    isError: isServiceAreaError,
    refetch: refetchServiceAreas,
    data: serviceAreas
  } = noddiAsync.useGet({
    type: URLKeys.getServiceAreasFromCoordinate,
    input: { lat: selectedAddress?.latitude, lng: selectedAddress?.longitude },
    queryConfig: {
      enabled: Boolean(selectedAddress)
    }
  });

  const hasServiceCategories = Boolean(serviceAreas?.serviceCategories?.length ?? 0 > 0);

  useEffect(() => {
    if (selectedAddress) {
      refetchServiceAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedAddress && serviceAreas) {
      if (!hasServiceCategories) {
        return clearAddress();
      }

      return onSelectAddress(selectedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAreas]);

  return (
    <>
      <AddressPicker
        onSelectAddress={async (address) => {
          if (!address) {
            return;
          }
          setSelectedAddress(address);
        }}
        translations={translations}
        {...params}
      />
      {isServiceAreasLoading && <NoddiLinearProgressLoader />}
      {serviceAreas && !hasServiceCategories && (
        <TypographyWithInfoSign>{pickerTranslations.noServiceOfferedNotice}</TypographyWithInfoSign>
      )}
      {serviceAreas && !hasStreetNumber(selectedAddress) && (
        <TypographyWithInfoSign>{pickerTranslations.noStreetNumberNotice}</TypographyWithInfoSign>
      )}
      {isServiceAreaError && <ErrorMessage message={pickerTranslations.somethingWentWrongWhileFetchingAddress} />}
    </>
  );
};

export { AvailableAddressPicker };
